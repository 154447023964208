import request from "@/utils/request.js"

//查询菜单接口
export function getMenuList(query) {
    return request({
        url: `/api/menu/getMenu`,
        method: 'get',
        params: {projectId: query.projectId}
    })
}

//新增菜单接口
export function addMenu(query) {
    return request({
        url: '/api/menu/saveMenu',
        method: 'post',
        data: query
    })
}

//修改菜单接口
export function editMenu(query) {
    return request({
        url: '/api/menu/updatedMenu',
        method: 'post',
        data: query
    })
}

//删除菜单接口
export function deleteMenu(query) {
    return request({
        url: `/api/menu/deletedMenu`,
        method: 'get',
        params: {projectId: query.projectId,id:query.id}
    })
}

//根据id查询菜单
export function getListById(query) {
    return request({
        url: `/api/menu/getMenuById`,
        method: 'get',
        params: {id:query.id}
    })
}

//菜单设备查询
export function getMenuByEquipment(query) {
    return request({
        url: `/api/menuRelation/get`,
        method: 'get',
        params: {menuId:query.menuId,projectId:query.projectId}
    })
}

//绑定设备
export function bindEquip(query) {
    return request({
        url: '/api/menuRelation/save',
        method: 'post',
        data: query
    })
}
