import request from "@/utils/request"


//查询项目
export function getProject(query) {
    return request({
        url: "/api/project/getProject",
        method: "post",
        data: query
    })
}

//新增项目
export function addProject(query) {
    return request({
        url: "/api/project/saveProject",
        method: "post",
        data: query
    })
}

//修改项目
export function editProject(query) {
    return request({
        url: "/api/project/updatedProject",
        method: "post",
        data: query
    })
}

//删除
export function delProject(query) {
    return request({
        url: "/api/project/deletedProject",
        method: "post",
        data: query
    })
}

