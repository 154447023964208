<template>
  <div>
    <div class="mytree">
      <el-tree
         height="100%"
        :data="newData"
        node-key="id"
        class="tree-class tree-calc-height"
        :props="defaultProps"
        highlight-current
        default-expand-all
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node dlt-flex-between" style="display: flex;align-items: center;justify-content: space-between;width: 500px;" slot-scope="{ node, data }">
          <div class="title-style" :title="node.label" style="overflow: hidden;width: 50px;white-space: nowrap;text-overflow: ellipsis;">
            <span>
            {{ node.label }}
            </span>
          </div>
          <div style="display: flex">
<!--            <div>-->
<!--              <el-button type="text" style="font-size: 10px" @click="handleBind(node)">绑定</el-button>-->
<!--            </div>-->
            <el-dropdown trigger="click" @command="handleCommand">
              <div class="el-dropdown-link dlt-flex-center">
                <el-button style="font-size: 10px;margin-left: 10px" type="text"><i class="el-icon-d-arrow-right"></i></el-button>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="['edit',{node, data}]">编辑</el-dropdown-item>
<!--                <el-dropdown-item :command="['subclasses',{node, data}]">新建子类</el-dropdown-item>-->
                <el-dropdown-item :command="['remove',{node, data}]">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<script>

export default {
  props: ['treeData', 'srcList', 'authority'],
  data() {
    return {
      treeId:1,//默认高亮
      uploadImg: '',
      newData: this.treeData || [],
      authority1: this.authority || [],
      treeExpandData: [],
      defaultProps: {
        label: 'parkName',
        value: 'parkCode',
        children: 'children',
        name: ''
      }
    }
  },
  watch: {
    srcList(curVal, oldVal) {
      if (curVal) {
        this.uploadImg = curVal
      }
    },
    treeData: {
      handler(newValue, oldValue) {
        this.newData = newValue
      },
      deep: true // 默认值是 false，代表是否深度监听
    },
  },
  methods: {
    handleNodeClick(data) {
      this.$emit('treeCall', data)
    },

    handleCommand(command) {
      if (command[0] === 'edit') {
        this.$emit('handleCommand', 'edit', command[1])
      } else if (command[0] === 'subclasses') {
        this.$emit('handleCommand', 'subclasses', command[1])
      } else if (command[0] === 'remove') {
        this.$emit('handleCommand', 'remove', command[1])
      }
    },
    //绑定
    handleBind(node){
      this.$emit('handleBind', node)
    },
    /*新建子类*/
    subclasses(node, data) {

    },

    edit(data) {
      const newChild = { id: id++, label: 'testtest', children: [] }
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      data.children.push(newChild)
    },

    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    }
  },
  mounted() {
    this.newData = this.treeData
  }
}
</script>

<style scoped lang="scss">
.tree-class {
  font-size: 12px;
  font-weight: 500;
  color: #343434;
}

.tree-title {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;

  .tree-h3 {
    margin-left: 8px;
  }
}

.tree-btn {
  text-align: center;
  .el-button {
    width: 100%;
    border: 1px solid #333;
  }
}

.title-style{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
  text-align: center;
}

</style>
<style lang="scss" type="text/css" scoped>
::v-deep .mytree {
  .el-tree > .el-tree-node:after {
    border-top: none;
  }
  .el-tree-node:before {
    border: none;
  }
  .el-tree-node:after {
    border: none;
  }
  .el-tree-node:before {
   border: none;
  }
  .el-tree-node:after {
    border: none;
  }
}
.tree-default{
  >>> .el-tree-node {
    position: relative;
    padding-left: 16px; // 缩进量
  }
  >>> .el-tree-node__content {
    height: 32px;
    padding-left: 0px !important;
  }
  >>> .el-tree-node__children {
    padding-left: 16px; // 缩进量
  }

  // 竖线
  >>> .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #B5BCC6;

  }
  // 当前层最后一个节点的竖线高度固定
  >>> .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  >>> .el-tree-node::after {
    content: "";
    width: 24px;
    height: 20px;
    position: absolute;
    left: -3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #B5BCC6;

  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  >>> & > .el-tree-node::after {
    border-top: none;
  }
  >>> & > .el-tree-node::before {
    border-left: none;
  }

  // 展开关闭的icon
  >>> .el-tree-node__expand-icon{
    font-size: 5px;
    background: #374D6C;
    color: #fff;
    margin: 5px;
    padding: 2px;
    // 叶子节点（无子节点）
    &.is-leaf{
      padding-left: 5px;
      display: none;
      & +.el-tree-node__label{
        margin-left: 10px;
      }

    }
  }
}

</style>
<style lang = "scss">
.tree-class {
  /* 树形节点编辑按钮 */
  .el-button {
    .el-icon-more {
      transform: rotate(90deg);
      color: #c3c3c3;
    }
  }

  .el-tree-node__expand-icon {
    color: #6F767E;

    &.is-leaf {
      color: transparent !important;
      cursor: default !important;
    }
  }

  .el-tree-node__content {
    &:hover {
      color: #226fdf;
      font-weight: bold;
    }
  }
  .el-tree > .el-tree-node:after {
    border-top: none;
  }
}

// tree 自定义高亮
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  color: #226fdf;
  font-weight: bold;
}
</style>
