import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjectPage from '../views/projectPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index' // 将根路径重定向到 首页
  },
  {
    path: '/index',
    name: 'index',
    component: HomeView
  },
  {
    path: '/projectPage/:id',
    name: 'projectPage',
    component: ProjectPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
