import request from "@/utils/request.js";

//查询设备
export function getEquipment(query) {
    return request({
        url: "/api/equipmentOrigin/getAllEquipmentOrigin",
        method: "post",
        data: query
    })
}

//新增设备
export function addEquipment(query) {
    return request({
        url: "/api/equipmentOrigin/saveEquipmentOrigin",
        method: "post",
        data: query
    })
}

//修改设备
export function editEquipment(query) {
    return request({
        url: "/api/equipmentOrigin/updateEquipmentOrigin",
        method: "post",
        data: query
    })
}

//删除设备
export function delEquipment(query) {
    return request({
        url: "/api/equipmentOrigin/deleteEquipmentOrigin",
        method: 'post',
        data: query
    })
}

//根据id查询设备信息
export function getEquipmentById(query) {
    return request({
        url: "/api/equipmentOrigin/getOneEquipmentOrigin",
        method: 'get',
        params: {uid:query.uid}
    })
}

//新增设备
export function saveListEquipmentOrigin(query) {
    return request({
        url: "/api/equipmentOrigin/saveListEquipmentOrigin",
        method: "post",
        data: query
    })
}
