import request from "@/utils/request"


//查询项目
export function getListCategory(query) {
    return request({
        url: "/api/category/getListCategory",
        method: "get",
        params: {projectId:query}
    })
}

//新增项目
export function addCategory(query) {
    return request({
        url: "/api/category/saveCategory",
        method: "post",
        data: query
    })
}

//修改项目
export function updateCategory(query) {
    return request({
        url: "/api/category/updateCategory",
        method: "post",
        data: query
    })
}

//删除
export function deleteCategory(query) {
    return request({
        url: "/api/category/deleteCategory",
        method: "post",
        data: query
    })
}

//根据id查询设备信息
export function getCategory(query) {
    return request({
        url: "/api/category/getCategory",
        method: 'get',
        params: {id:query.id}
    })
}

