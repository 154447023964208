<template>
  <el-container>
    <!-- 树形菜单 -->
    <el-aside width="250px" style="height:100%">
      <el-header>
        <el-button @click="addMenuData">新增园区</el-button>
      </el-header>
      <el-main>
        <el-button @click="getByData" type="text">全部设备</el-button>
        <SideTree :treeData="itemData" @treeCall="treeCall" @handleCommand="handleCommand">
          <slot name="bind">

          </slot>
        </SideTree>
      </el-main>
    </el-aside>

    <!--查询-->
    <el-main>
      <el-header height="auto">
        <el-form
            :model="query"
            ref="queryForm"
            size="small"
            :inline="true"
            @submit.native.prevent
        >
          <el-form-item>
            <el-button type="primary" icon="el-icon-back" @click="goHome">返回至上一级</el-button>
          </el-form-item>
          <el-form-item label="设备id">
            <el-input v-model="query.id" placeholder="请输入设备编码" clearable @change="getTableList"/>
          </el-form-item>
          <el-form-item label="设备名称">
            <el-input v-model="query.name" placeholder="请输入设备名称" clearable @change="getTableList"/>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select v-model="query.category" placeholder="请选择设备类型" @change="getTableList" clearable>
              <el-option v-for="item in categoryData" :key="item.id" :label="item.category"
                         :value="item.category"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="getTableList">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            <el-button type="primary" @click="addEquipmentBtn('normal')">添加设备</el-button>
            <el-button type="primary" @click="handleImport" :disabled="importDisabled">导入设备</el-button>
            <el-button type="primary" @click="manageType">管理设备类型</el-button>
          </el-form-item>
        </el-form>
      </el-header>
      <!-- 表格展示 -->
      <el-main class="pd-lr">
        <el-table
            :max-height="730"
            style="height:730px;overflow-y: auto;"
            lazy
            tooltip-effect="dark"
            ref="tableRef"
            :tree-props="{children: 'children',hasChildren: 'hasChildren'}"
            row-key="id"
            :data="tableData"
        >
          <el-table-column label="序号" type="index" align="center"/>
          <el-table-column
              align="center"
              prop="uid"
              label="uid"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="puid"
              label="父uid"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="id"
              label="id"
              width="250px"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="name"
              label="设备名称"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="category"
              label="设备类型"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="alarmMonitors"
              label="报警监控"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="createdTime"
              label="创建时间"
              show-overflow-tooltip
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="updatedTime"
              label="修改时间"
              show-overflow-tooltip
              width="200"
          >
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              width="300"
          >
            <template slot-scope="scope">
              <el-button size="mini" type="text"
                         @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
              <el-button size="mini" type="text" @click="insertRow('up', scope.$index)">向上插入</el-button>
              <el-button size="mini" type="text" @click="insertRow('down', scope.$index)">向下插入</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer>
        <el-button size="mini" @click="pushToDataBase">同步保存至数据库</el-button>
      </el-footer>
    </el-main>

    <!-- 弹窗 -->
    <!--  园区  -->
    <el-dialog
        :visible.sync="editPop"
        width="438px"
        :close-on-click-modal="false"
        :title="isMenuEdit == 'add' ? '添加园区' : '编辑园区'"
        v-if="editPop"
    >
      <el-form :model="form" ref="form" :rules="parkRules">
        <el-form-item label="园区名称" prop="parkName">
          <el-input v-model.trim="form.parkName" placeholder="请输入园区名称" autocomplete="off" maxlength="20"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="园区编码" prop="parkCode">
          <el-input v-model.trim="form.parkCode" placeholder="请输入园区编码" autocomplete="off" maxlength="20"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submitForm()">确 认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <!-- 添加、编辑设备 -->
    <el-dialog
        :visible.sync="equipment"
        width="438px"
        top="5vh"
        :close-on-click-modal="false"
        :title="isEquipment == 'addEquipment' ? '添加设备' : '编辑设备'"
        v-if="equipment"
    >
      <el-form :model="formEquipment" ref="formEquipment" :rules="equipmentRules">
        <el-form-item label="uid" prop="uid">
          <el-input v-model.trim="formEquipment.uid" placeholder="请输入设备uid" autocomplete="off"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="父uid" prop="puid">
          <el-input v-model.trim="formEquipment.puid" placeholder="请输入设备父uid" autocomplete="off"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="id" prop="id">
          <el-input v-model.trim="formEquipment.id" placeholder="请输入设备id" autocomplete="off" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="formEquipment.name" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="category">
          <el-select v-model="formEquipment.category" placeholder="请选择设备类型" @change="getTableList" clearable>
            <el-option v-for="item in categoryData" :key="item.id" :label="item.category"
                       :value="item.category"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属园区" prop="parkCode">
          <el-select v-model="formEquipment.parkCode" placeholder="请选择所属园区" @change="changePark" clearable>
            <el-option v-for="item in itemData" :key="item.parkCode" :label="item.parkName"
                       :value="item.parkCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报警监控" prop="alarmMonitors">
          <el-input v-model.trim="formEquipment.alarmMonitors" placeholder="请输入报警监控" autocomplete="off"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="abolish">取 消</el-button>
          <el-button type="primary" @click="submitFormEquipment()">确 认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 设备类型管理 -->
    <el-dialog
        :visible.sync="manageDialog"
        width="1200px"
        :close-on-click-modal="false"
        title='设备类型管理'
        v-if="manageDialog"
    >
      <el-header height="auto" style="text-align: right">
        <el-button type="primary" @click="addCategory">添加设备类型</el-button>
      </el-header>
      <el-main class="pd-lr">
        <el-table
            tooltip-effect="dark"
            ref="tableRef"
            row-key="id"
            :data="categoryData"
        >
          <el-table-column align="center" prop="id" label="id"></el-table-column>
          <el-table-column align="center" prop="category" label="类别名称"></el-table-column>
          <el-table-column align="center" prop="createdTime" label="创建时间"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="categoryEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="text" @click="categoryDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-dialog>

    <!-- 添加，编辑类型 -->
    <el-dialog
        :visible.sync="typeEditPop"
        width="400px"
        append-to-body
        :close-on-click-modal="false"
        :title="!isTypeEdit ? '添加类型' : '编辑类型'"
        :before-close="categoryClose"
        v-if="typeEditPop"
    >
      <el-form :model="categoryForm" ref="categoryForm" :rules="categoryRules">
        <el-form-item label="类型名称" prop="category">
          <el-input v-model.trim="categoryForm.category" placeholder="请输入类型名称" autocomplete="off"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item style=" display: flex;justify-content: center;">
          <el-button @click="categoryClose">取 消</el-button>
          <el-button type="primary" @click="submitCategoryForm()">确 认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 用户导入设备 -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="导入设备"
        :visible.sync="upload.open"
        width="400px"
        append-to-body
    >
      <el-upload
          ref="upload"
          :limit="1"
          accept=".xlsx, .xls"
          :action="upload.url"
          :disabled="upload.isUploading"
          :on-progress="handleFileUploadProgress"
          :on-success="handleFileSuccess"
          :auto-upload="false"
          drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip text-center" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>

  </el-container>
</template>


<script>
import SideTree from '@/components/SideTree'
import {getMenuList, addMenu, editMenu, deleteMenu, getListById} from '@/api/menu/menu'
import {
  getEquipment,
  editEquipment,
  delEquipment,
  getEquipmentById,
  saveListEquipmentOrigin
} from '@/api/equipment/equipment'
import {getListCategory, addCategory, getCategory, updateCategory, deleteCategory} from '@/api/type/type'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Sortable from 'sortablejs';

export default {
  name: "projectPage",
  components: {
    SideTree,
    Sortable
  },
  data() {
    return {
      equipmentStorage: [],//存贮设备下拉
      equipment: false,//设备弹窗是否打开
      isEquipment: '',//设备弹窗标题
      pageTotal: 0,//存贮数据总条数
      tableData: [],//设备表格存贮
      form: {},//园区表单
      formEquipment: {},//设备表单
      formParam: {},//设备绑定表单
      parentId: null,//上级id
      isMenuEdit: '',//弹窗标题
      editPop: false, //编辑、新增弹窗
      itemData: [],//存贮属性园区数据
      updateTreeData: [], //编辑时的树形园区
      projectId: this.$route.params.id,//存贮首页传来的id
      // 表格传值分页、模糊查询
      query: {
        parkName: '', //园区名称模糊查询
        parentId: null//父级id
      },
      bindDialog: false,//绑定设备弹窗
      // up为向上添加一行，down为向下添加一行
      direction: null,
      // 选中行的index
      checkRowIndex: null,
      // 设备类型管理弹窗显隐
      manageDialog: false,
      // 设备类型列表
      categoryData: {},
      // 新增类型弹窗显隐
      typeEditPop: false,
      // 修改类型 true 新增类型false
      isTypeEdit: false,
      // 新增、修改类型表单
      categoryForm: {},
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 是否禁用上传
        isUploading: false,
        // 上传的地址
        url: "/api/equipmentOrigin/importEquipmentOrigin?projectId=" + this.$route.params.id,
      },
      //是否禁用导入
      importDisabled: true,

      // 园区校验规则
      parkRules: {
        parkName: [
          {required: true, message: "园区名称不能为空", trigger: "blur"},
        ],
        parkCode: [
          {required: true, message: "园区编码不能为空", trigger: "blur"},
        ],
      },
      // 设备校验规则
      equipmentRules: {
        uid: [
          {required: true, message: "uid不能为空", trigger: "blur"},
        ],
        parkCode: [
          {required: true, message: "所属园区不能为空", trigger: "change"},
        ],
      },
      // 类型校验规则
      categoryRules: {
        category: [
          {required: true, message: "类型名称不能为空", trigger: "blur"},
        ],
      },
    }
  },
  created() {
    this.getMenu();
    this.getTableList();
    this.getListCategory();
  },
  mounted() {
    //项目id
    this.projectId = this.$route.params.id;
    this.rowDrop();
  },
  methods: {
    // 返回到首页
    goHome() {
      this.$router.push("/");
    },
    // 添加一行
    insertRow(direction, index) {
      this.direction = direction
      this.checkRowIndex = index
      this.addEquipmentBtn()
    },
    // 行拖拽
    rowDrop() {
      // 要侦听拖拽响应的DOM对象（数据存储在.el-table__body-wrapper > .el-table__row > tbody标签中（el-table的数据部分的“最外层”class名为el-table__body-wrapper））
      const tbody = document.querySelector('.el-table__body-wrapper tbody');
      const that = this;
      Sortable.create(tbody, {
        // 结束拖拽后的回调函数
        onEnd({newIndex, oldIndex}) {
          const currentRow = that.tableData.splice(oldIndex, 1)[0]; // 将oldIndex位置的数据删除并取出，oldIndex后面位置的数据会依次前移一位
          that.tableData.splice(newIndex, 0, currentRow); // 将被删除元素插入到新位置（currRow表示上面的被删除数据）
        }
      })
    },
    //数据重新加载
    getByData() {
      this.query.parkCode = null
      this.importDisabled = true
      this.equipmentReset()
      this.getMenu()
    },
    //设备重置
    equipmentReset() {
      let param = {
        projectId: this.$route.params.id,
      }
      getEquipment(param).then(res => {
        this.tableData = this.equipmentStorage = res.data
        this.pageTotal = res.total == null ? 0 : res.total
      })
    },
    //重置
    reset() {
      this.form = {
        parkName: '',
        parentId: this.query.parentId === undefined || this.query.parentId === null ? 0 : this.query.parentId
      }
    },
    /*弹窗关闭*/
    cancel() {
      this.editPop = false
      this.reset()
    },
    //添加园区
    addMenuData() {
      this.reset()
      this.isMenuEdit = 'add'
      this.editPop = true
    },
    /*根据树形园区id获取子级列表*/
    treeCall(node) {
      if (node.id != undefined || node.id != null) {
        this.query.parkCode = node.parkCode
        if (this.upload.url.includes("&parkCode")) {
          this.upload.url = this.upload.url.split('&parkCode')[0];
        }
        this.upload.url += "&parkCode=" + node.parkCode
        this.importDisabled = false
        this.getTableList()
      }
    },
    // 树形园区调用父组件传值
    handleCommand(type, val) {
      switch (type) {
        case 'edit': //修改节点
          this.upDateMenu(val.data)
          break
        case 'subclasses': //新增节点
          this.addMenuData(val.data)
          break
        case 'remove': //删除节点
          this.deleteMenu(val.data)
          break
      }
    },
    //编辑园区
    upDateMenu(val) {
      this.reset()
      this.isMenuEdit = 'edit'
      this.editPop = true
      let param = {
        id: val.id
      }
      getListById(param).then(res => {
        this.form = res.data
      })
    },

    //删除园区
    deleteMenu(val) {
      this.$confirm('是否要删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          projectId: this.projectId,
          id: val.id
        }
        deleteMenu(param).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.getMenu();
          } else {
            this.$message(res.message)
          }
        })
      }).catch(() => {
      });
    },
    //获取园区列表
    getMenu(projectId) {
      let param = {
        projectId: this.projectId
      }
      getMenuList(param).then(res => {
        if (res.code == 200) {
          if (projectId == undefined) { //id为undefined时代表左侧树形园区
            this.itemData = res.data
          } else { //不为undefined的时候代表编辑功能，此时要筛选数据
            this.updateTreeData = res.data
          }
        }
      })
    },
    /*提交按钮--增改*/
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.parentId == null) {
            this.form.parentId = 0
          }
          if (this.parentId == null) {
            this.parentId = 0
          }
          if (this.isMenuEdit == 'add') {
            this.form.parentId = this.parentId
            let form = {
              parentId: this.form.parentId,
              projectId: this.$route.params.id,
              parkName: this.form.parkName,
              parkCode: this.form.parkCode
            }
            addMenu(form).then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: 'success'
                });
                this.editPop = false
                this.getMenu()
              }
            })
          } else {
            editMenu(this.form).then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: 'success'
                });
                this.editPop = false
                this.getMenu()
              }
            })
          }
          this.query.parkCode = null
        }
      })
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.query = {
        parkName: '', //园区名称模糊查询
        parentId: null//父级id
      };
      this.getByData();
    },

    //根据树形园区id获取设备列表
    getTableList(data) {
      if (data != undefined && data.id != undefined) {
        this.query.parentId = data.id
      }
      let param = {
        projectId: this.$route.params.id,
        parkCode: this.query.parkCode,
        id: this.query.id,
        name: this.query.name,
        category: this.query.category
      }
      getEquipment(param).then(res => {
        this.tableData = res.data
        this.pageTotal = res.total == null ? 0 : res.total
      })
    },
    /**
     * 切换选中的园区
     */
    changePark() {
      //强制刷新
      this.$forceUpdate()
    },
    //添加设备数据
    addEquipmentBtn(direction) {
      this.close();
      this.equipment = true
      this.isEquipment = 'addEquipment'
      if (this.query.parkCode) {
        this.formEquipment.parkCode = this.query.parkCode
      }
      if (direction) {
        this.direction = direction
      }
    },
    //设备编辑
    handleEdit(row) {
      this.isEquipment = 'editEquipment'
      let param = {
        uid: row.uid
      }
      getEquipmentById(param).then(res => {
        this.formEquipment = res.data
        this.equipment = true
      })
    },
    //设备删除
    handleDelete(row) {
      this.$confirm('是否要删除该设备数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          projectId: this.projectId,
          uid: row.uid
        }
        delEquipment(param).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.getTableList();
          } else {
            this.$message(res.message)
          }
        })
      }).catch(() => {
      });
    },
    abolish() {
      this.equipment = false
    },
    close() {
      this.formEquipment = {}
    },
    // 设备提交
    submitFormEquipment() {
      this.$refs.formEquipment.validate(valid => {
        if (valid) {
          if (this.formEquipment.parkCode) {
            this.formEquipment.parkName = this.itemData.find(i => i.parkCode == this.formEquipment.parkCode).parkName
          }
          if (this.isEquipment != 'editEquipment') {
            this.formEquipment.projectId = this.projectId
            if (this.direction === 'up' || this.direction === 'normal') {
              this.tableData.splice(this.checkRowIndex, 0, this.formEquipment); // 向上插入一行
            } else if (this.direction === 'down') {
              this.tableData.splice(this.checkRowIndex + 1, 0, this.formEquipment); // 向下插入一行
            }
            this.pushToDataBase();
            this.equipment = false;
            this.close()
          } else {
            editEquipment(this.formEquipment).then(res => {
              if (res.code == 200) {
                this.$message.success(res.message)
                this.equipment = false
                this.close();
                this.getByData();
              }
            })
          }
        }
      })
    },
    // 批量推送至数据库
    pushToDataBase() {
      this.tableData.forEach((value, index) => {
        value.sort = index
      })
      saveListEquipmentOrigin(this.tableData).then(res => {
        if (res.code == 200) {
          this.$message.success("同步至数据库成功")
          this.getByData()
        } else {
          this.$message.error(res.message)
        }
      })
    },

    /**
     * 类型控制
     */

    // 打开设备类型控制页面
    manageType() {
      this.manageDialog = true
      this.getListCategory();
    },

    //设备类型列表查询
    getListCategory() {
      getListCategory(this.projectId.toString()).then(res => {
        this.categoryData = res.data
      })
    },
    //添加设备类型数据
    addCategory() {
      this.typeEditPop = true
      this.isTypeEdit = false
    },
    // 设备类型提交
    submitCategoryForm() {
      this.$refs.categoryForm.validate(valid => {
        if (valid) {
          if (!this.isTypeEdit) {
            this.categoryForm.projectId = this.projectId
            addCategory(this.categoryForm).then(res => {
              if (res.code == 200) {
                this.$message.success(res.message)
                this.categoryClose()
                this.getListCategory()
              } else {
                this.$message.error(res.message)
              }
            })

          } else {
            updateCategory(this.categoryForm).then(res => {
              if (res.code == 200) {
                this.$message.success(res.message)
                this.categoryClose()
                this.getListCategory()
              }
            })
          }
        }
      })
    },

    // 关闭设备类型表单弹窗
    categoryClose() {
      this.typeEditPop = false
      this.categoryForm = {}
    },
    // 设备类型编辑弹窗
    categoryEdit(row) {
      this.isTypeEdit = true
      let param = {
        id: row.id
      }
      getCategory(param).then(res => {
        this.categoryForm = res.data
        this.typeEditPop = true
      })
    },
    // 设备类型删除
    categoryDelete(row) {
      this.$confirm('是否要删除该设备类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          id: row.id,
          projectId: this.projectId
        }
        deleteCategory(param).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.getListCategory();
          } else {
            this.$message(res.message)
          }
        })
      }).catch(() => {
      });
    },

    /** 导入按钮操作 */
    handleImport() {
      this.upload.open = true;
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(
          "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
          response.message +
          "</div>",
          "导入结果",
          {dangerouslyUseHTMLString: true}
      );
      this.getByData();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
  }
}
</script>

<style scoped>

</style>
