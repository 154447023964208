import axios from 'axios';

// 创建 Axios 实例
const instance = axios.create({
  // baseURL: 'https://api.example.com', // 设置请求的基础 URL
  timeout: 5000, // 设置请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以进行一些处理，如添加请求头、处理请求参数等
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 在收到响应数据之前可以进行一些处理
    return response.data;
  },
  (error) => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

// 封装 GET 请求
export function get(url, params) {
  return instance.get(url, { params });
}

// 封装 POST 请求
export function post(url, data) {
  return instance.post(url, data);
}

// 其他请求方法的封装，如 PUT、DELETE 等

export default instance;
