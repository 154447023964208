<template>
  <div class="home">
    <h1>项目列表</h1>
    <el-container>
      <el-header>
        <el-button type="primary" @click="addHandle">新增</el-button>
      </el-header>
      <el-main>
        <el-table
            :max-height="640"
            style="height: 640px;overflow-y: auto;"
            :data="projectManageData"
        >
          <el-table-column
              align="center"
              prop="id"
              label="id"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="projectName"
              label="项目名称"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="createdTime"
              label="创建时间"
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="updatedTime"
              label="更新时间"
          >
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              >
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
              <el-button size="mini" type="text" @click="enterProject(scope.row)">进入项目</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <Pagination @pagination="handleSizeChange" :total="pageTotal" :pageSizes="[10, 15, 20, 25]"
                      :page="query.current"
                      :limit="query.pageSize"
          ></Pagination>
        </div>
      </el-main>
      <el-dialog
          :visible.sync="itemPop"
          width="438px"
          :close-on-click-modal="false"
          :title="edit == 'add' ? '新增项目' : '编辑项目'"
          v-if="itemPop"
      >
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="项目名称" prop="projectName">
            <el-input v-model="form.projectName" placeholder="请输入项目名称"></el-input>
          </el-form-item>
          <el-form-item label="项目备注" prop="remark">
            <el-input v-model="form.remark" autocomplete="off" placeholder="请输入项目备注" maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right;">
              <el-button @click="cancel">取 消</el-button>
              <el-button type="primary" @click="submitForm()" v-shaking>确 认</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import {getProject,addProject,editProject,delProject} from '@/api/project/project'
import Pagination from '@/components/Pagination'
export default {
  components:{
    Pagination
  },
  data() {
    return {
      itemPop:false,//弹窗
      edit: '',//弹窗标题
      projectManageData:[],//存贮项目管理列表
      pageTotal: 0,//默认总条数
      //表格传值分页、模糊查询
      query: {
        current: 1,
        pageSize: 10,
      },
      //表单
      form:{},
      // 表单校验
      rules: {
        projectName: [
          { required: true, message: "项目名称不能为空", trigger: "blur" }
        ],
      },
    }
  },

  created() {
    this.getProjectList();
  },
  methods: {
    /*分页*/
    handleSizeChange(val) {
      this.query.pageSize = val.limit
      this.query.current = val.page
      this.getProjectList()
    },
    //获取项目管理列表
    getProjectList(){
      const formData = new FormData();
      formData.append('current',this.query.current)
      formData.append('pageSize',this.query.pageSize)
      getProject(formData).then(res => {
       if(res.code == 200){
         this.projectManageData = res.data
         this.pageTotal = res.total
       }
      })
    },
    //根据项目id跳转设备项目页面
    enterProject(row) {
      this.$router.push({
        name: 'projectPage',
        params: {
          id: row.id
        }
      });
    },
    //新增项目
    addHandle(){
      this.edit='add'
      this.itemPop = true
    },
    //删除项目
    handleDelete(row){
      this.$confirm('是否要删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const formData = new FormData();
        formData.append('id', row.id);
        delProject(formData).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.getProjectList();
          }
        })
      }).catch(() => {});
    },
    //修改项目
    handleEdit(row){
      this.edit='edit'
      this.form = row
      this.itemPop = true
    },
    //弹窗取消 - 表单重置
    cancel(){
      this.itemPop = false
      this.form = {
        projectName:'',
        remark:''
      }
    },
    //弹窗确定按钮
    submitForm(){
      this.$refs.form.validate(valid => {
        if(valid) {
          if(this.edit != 'edit'){
            addProject(this.form).then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: 'success'
                });
                this.cancel()
                this.getProjectList();
              }
            })
          } else {
            editProject(this.form).then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: 'success'
                });
                this.cancel()
                this.getProjectList();
              }
            })
          }
        }
      })
    },
  }
}
</script>
<style lang="css">
.home{
  width: 100%;
  height: 100%;
  background-color: #dbdbdb
}
</style>
